export enum MenuPageEvents {
  MeditationPage = 'meditation_page',
  MusicPage = 'music_page',
  ProgramsPage = 'programs_page',
  FavoritesPage = 'favorites_page',
}

export enum PageEvents {
  Search = 'search',
  ForYou = 'for_you',
  NotFound = '404',
  ViewAll = 'view_all',
  MomentDetails = 'moment_details',
  MeditationDetails = 'meditation_details',
  ProgramDetails = 'program_details',
  BinauralPopup = 'binaural_info_popup',
  SoundHealingPopup = 'sound_healing_info_popup',
  WhiteNoisePopup = 'white_noise_info_popup',
  MyProfile = 'my_profile',
  MyProfileAccountDetails = 'my_profile_account_details',
  MyProfileLanguageSettings = 'my_profile_language_settings',
  MyProfileChangeTimezone = 'my_profile_change_timezone',
  MyProfileGiftVoucher = 'my_profile_redeem_gift_voucher',
  Player = 'player',
  PlayerFinished = 'player_finished',
  AllYogaVideos = 'all_yoga_videos',
  YogaDetail = 'yoga_video_details',
  AllFlowVideos = 'all_flow_videos',
  FlowDetail = 'flow_video_details',
}
