import { CombinedState, createAsyncThunk } from '@reduxjs/toolkit';
import { Selectors as audioTracksSelectors } from 'domains/audio-tracks';
import { Selectors as courseSelectors } from 'domains/courses';
import { Selectors as meditationSelectors } from 'domains/meditations';
import { Selectors as yogaSelectors } from 'domains/yoga';
import { Selectors as flowSelectors } from 'domains/flow';
import { Selectors as musicSelectors } from 'domains/music';
import { Selectors as userSelectors } from 'domains/user';
import { DayParts } from 'models/day-parts';
import { MusicCategoryTypes } from 'models/music-category';
import { State as RootState } from 'models/redux-state';
import { YogaFlowListOptions } from 'models/yoga-flow';
import {
  ActionEvents,
  LeanplumService,
  MenuPageEvents,
  MixpanelService,
  PageEvents,
  PlayerTypes,
  SuperProperties,
  ViewAllSections,
} from 'services/tracking';
import { EventProps } from 'services/tracking/models/event-props';
import { getSuperProperties } from './base-thunks';
import { NAME } from './constants';

export const trackPage = createAsyncThunk<
  void,
  {
    pageEvent: PageEvents | MenuPageEvents;
    eventProps: EventProps<PageEvents | MenuPageEvents>;
  },
  { state: CombinedState<RootState> }
>(`${NAME}/track-page`, async ({ pageEvent, eventProps }, thunkAPI) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('tracking - pageEvent', pageEvent, 'variables', eventProps);
  }

  const user = userSelectors.getUser(thunkAPI.getState());
  if (user) {
    const leanplum = new LeanplumService(user.uid);
    leanplum.track(ActionEvents.ScreenViewed, { screen_name: pageEvent, ...eventProps });

    const { payload: superProperties } = await thunkAPI.dispatch(getSuperProperties());
    const mixpanel = new MixpanelService(user.uid, superProperties as SuperProperties);
    mixpanel.track(ActionEvents.ScreenViewed, { screen_name: pageEvent, ...eventProps });
  }
});

export const trackSearch = createAsyncThunk<void, undefined>(`${NAME}/track-page--search`, async (_, thunkAPI) => {
  await thunkAPI.dispatch(
    trackPage({
      pageEvent: PageEvents.Search,
      eventProps: { category: 'discover' },
    }),
  );
});

export const trackForYouPage = createAsyncThunk<void, DayParts, { state: CombinedState<RootState> }>(
  `${NAME}/track-page--for-you`,
  async (dayPart, thunkAPI) => {
    await thunkAPI.dispatch(
      trackPage({
        pageEvent: PageEvents.ForYou,
        eventProps: { category: PageEvents.ForYou, daypart: dayPart },
      }),
    );
  },
);

export const trackNotFoundPage = createAsyncThunk<void, string, { state: CombinedState<RootState> }>(
  `${NAME}/track-page--not-found`,
  async (url, thunkAPI) => {
    await thunkAPI.dispatch(trackPage({ pageEvent: PageEvents.NotFound, eventProps: { category: 'error', url } }));
  },
);

export const trackViewAll = createAsyncThunk<void, ViewAllSections, { state: CombinedState<RootState> }>(
  `${NAME}/track-page--view-all`,
  async (section, thunkAPI) => {
    await thunkAPI.dispatch(
      trackPage({
        pageEvent: PageEvents.ViewAll,
        eventProps: { category: PageEvents.ViewAll, section, source: PageEvents.ForYou },
      }),
    );
  },
);

export const trackAllYogaVideos = createAsyncThunk<void, YogaFlowListOptions, { state: CombinedState<RootState> }>(
  `${NAME}/track-page--all-yoga-videos`,
  async (contentList, thunkAPI) => {
    await thunkAPI.dispatch(
      trackPage({
        pageEvent: PageEvents.AllYogaVideos,
        eventProps: { category: 'discover', content_list: contentList },
      }),
    );
  },
);

export const trackYogaDetail = createAsyncThunk<void, string, { state: CombinedState<RootState> }>(
  `${NAME}/track-page--yoga-detail`,
  async (uid, thunkAPI) => {
    const yogaItem = yogaSelectors.getItemByUid(thunkAPI.getState(), { uid });

    await thunkAPI.dispatch(
      trackPage({
        pageEvent: PageEvents.YogaDetail,
        eventProps: {
          category: 'flow_yoga',
          yoga_video_title: yogaItem.title,
        },
      }),
    );
  },
);

export const trackYogaPlayer = createAsyncThunk<void, string, { state: CombinedState<RootState> }>(
  `${NAME}/track-page--yoga-player`,
  async (uid, thunkAPI) => {
    const yogaItem = yogaSelectors.getItemByTrackUid(thunkAPI.getState(), { uid });
    const track = audioTracksSelectors.getAudioTrackByUid(thunkAPI.getState(), { uid });

    await thunkAPI.dispatch(
      trackPage({
        pageEvent: PageEvents.Player,
        eventProps: {
          category: PageEvents.Player,
          type: PlayerTypes.Yoga,
          content_format: track.type,
          content_title: yogaItem.title,
          duration: track.duration / 60, // duration in minutes instead of seconds
          version_uuid: track.uid,
        },
      }),
    );
  },
);

export const trackYogaFinished = createAsyncThunk<void, { trackUid: string }, { state: CombinedState<RootState> }>(
  `${NAME}/track-page--yoga-finished`,
  async ({ trackUid }, thunkAPI) => {
    const yogaItem = yogaSelectors.getItemByTrackUid(thunkAPI.getState(), { uid: trackUid });
    const track = audioTracksSelectors.getAudioTrackByUid(thunkAPI.getState(), { uid: trackUid });

    if (yogaItem) {
      await thunkAPI.dispatch(
        trackPage({
          pageEvent: PageEvents.PlayerFinished,
          eventProps: {
            category: 'finished_flow',
            type: PlayerTypes.Yoga,
            content_title: yogaItem.title,
            duration: track.duration / 60, // duration in minutes instead of seconds
            version_uuid: track.uid,
          },
        }),
      );
    }
  },
);

export const trackAllFlowVideos = createAsyncThunk<void, YogaFlowListOptions, { state: CombinedState<RootState> }>(
  `${NAME}/track-page--all-yoga-videos`,
  async (contentList, thunkAPI) => {
    await thunkAPI.dispatch(
      trackPage({
        pageEvent: PageEvents.AllFlowVideos,
        eventProps: { category: 'discover', content_list: contentList },
      }),
    );
  },
);

export const trackFlowDetail = createAsyncThunk<void, string, { state: CombinedState<RootState> }>(
  `${NAME}/track-page--flow-detail`,
  async (uid, thunkAPI) => {
    const flowItem = flowSelectors.getItemByUid(thunkAPI.getState(), { uid });

    await thunkAPI.dispatch(
      trackPage({
        pageEvent: PageEvents.FlowDetail,
        eventProps: {
          category: 'flow_yoga',
          flow_video_title: flowItem.title,
        },
      }),
    );
  },
);

export const trackFlowPlayer = createAsyncThunk<void, string, { state: CombinedState<RootState> }>(
  `${NAME}/track-page--flow-player`,
  async (uid, thunkAPI) => {
    const flowItem = flowSelectors.getItemByTrackUid(thunkAPI.getState(), { uid });
    const track = audioTracksSelectors.getAudioTrackByUid(thunkAPI.getState(), { uid });

    await thunkAPI.dispatch(
      trackPage({
        pageEvent: PageEvents.Player,
        eventProps: {
          category: PageEvents.Player,
          type: PlayerTypes.Flow,
          content_format: track.type,
          content_title: flowItem.title,
          duration: track.duration / 60, // duration in minutes instead of seconds
          version_uuid: track.uid,
        },
      }),
    );
  },
);

export const trackFlowFinished = createAsyncThunk<void, { trackUid: string }, { state: CombinedState<RootState> }>(
  `${NAME}/track-page--flow-finished`,
  async ({ trackUid }, thunkAPI) => {
    const flowItem = flowSelectors.getItemByTrackUid(thunkAPI.getState(), { uid: trackUid });
    const track = audioTracksSelectors.getAudioTrackByUid(thunkAPI.getState(), { uid: trackUid });

    if (flowItem) {
      await thunkAPI.dispatch(
        trackPage({
          pageEvent: PageEvents.PlayerFinished,
          eventProps: {
            category: 'finished_flow',
            type: PlayerTypes.Flow,
            content_title: flowItem.title,
            duration: track.duration / 60, // duration in minutes instead of seconds
            version_uuid: track.uid,
          },
        }),
      );
    }
  },
);

export const trackMenuPage = createAsyncThunk<void, MenuPageEvents, { state: CombinedState<RootState> }>(
  `${NAME}/track-page--menu-page`,
  async (menuPage, thunkAPI) => {
    await thunkAPI.dispatch(trackPage({ pageEvent: menuPage, eventProps: { category: 'web-app-menu' } }));
  },
);

export const trackMomentDetail = createAsyncThunk<void, string, { state: CombinedState<RootState> }>(
  `${NAME}/track-page--moment-detail`,
  async (uid, thunkAPI) => {
    const moment = meditationSelectors.getMomentByUid(thunkAPI.getState(), { uid });
    const momentName = moment.title.split(' ').join('_').toLowerCase();

    await thunkAPI.dispatch(
      trackPage({
        pageEvent: PageEvents.MomentDetails,
        eventProps: { category: 'discover', moment_name: `${momentName}_${moment.locale}` },
      }),
    );
  },
);

export const trackMeditationDetail = createAsyncThunk<void, string, { state: CombinedState<RootState> }>(
  `${NAME}/track-page--meditation-detail`,
  async (uid, thunkAPI) => {
    const meditation = meditationSelectors.getMeditationByUid(thunkAPI.getState(), { uid });

    await thunkAPI.dispatch(
      trackPage({
        pageEvent: PageEvents.MeditationDetails,
        eventProps: {
          category: 'meditation',
          meditation_title: meditation.title,
          uuid_meditation: uid,
        },
      }),
    );
  },
);

export const trackMeditationPlayer = createAsyncThunk<void, string, { state: CombinedState<RootState> }>(
  `${NAME}/track-page--meditation-player`,
  async (uid, thunkAPI) => {
    const meditation = meditationSelectors.getMeditationByTrackUid(thunkAPI.getState(), { uid });
    const track = audioTracksSelectors.getAudioTrackByUid(thunkAPI.getState(), { uid });

    await thunkAPI.dispatch(
      trackPage({
        pageEvent: PageEvents.Player,
        eventProps: {
          category: PageEvents.Player,
          type: PlayerTypes.Meditation,
          content_format: track.type,
          content_title: meditation.title,
          duration: track.duration / 60, // duration in minutes instead of seconds
          version_uuid: track.uid,
        },
      }),
    );
  },
);

export const trackMeditationFinished = createAsyncThunk<
  void,
  { trackUid: string; includeRelated?: boolean },
  { state: CombinedState<RootState> }
>(`${NAME}/track-page--meditation-finished`, async ({ trackUid, includeRelated }, thunkAPI) => {
  const meditation = meditationSelectors.getMeditationByTrackUid(thunkAPI.getState(), { uid: trackUid });
  const track = audioTracksSelectors.getAudioTrackByUid(thunkAPI.getState(), { uid: trackUid });

  if (meditation) {
    await thunkAPI.dispatch(
      trackPage({
        pageEvent: PageEvents.PlayerFinished,
        eventProps: {
          category: 'finished_flow',
          type: PlayerTypes.Meditation,
          content_title: meditation.title,
          duration: track.duration / 60, // duration in minutes instead of seconds
          version_uuid: track.uid,
          continued_playing_music: !!includeRelated,
          continued_playing_music_title: includeRelated ? track?.related?.title : undefined,
        },
      }),
    );
  }
});

export const trackMusicCategory = createAsyncThunk<void, string, { state: CombinedState<RootState> }>(
  `${NAME}/track-page--music-category`,
  async (uid, thunkAPI) => {
    const musicCategory = musicSelectors.getCategoryByUid(thunkAPI.getState(), { uid });
    const categoryName = musicCategory.title.split(' ').join('_').toLowerCase();

    await thunkAPI.dispatch(
      trackPage({
        pageEvent: PageEvents.MomentDetails,
        eventProps: { category: 'discover', moment_name: `${categoryName}_${musicCategory.locale}` },
      }),
    );
  },
);

export const trackMusicCategoryInformation = createAsyncThunk<
  void,
  MusicCategoryTypes,
  { state: CombinedState<RootState> }
>(`${NAME}/track-page--music-category-information`, async (category, thunkAPI) => {
  const pageEvent = {
    [MusicCategoryTypes.Binaural]: PageEvents.BinauralPopup,
    [MusicCategoryTypes.SoundHealing]: PageEvents.SoundHealingPopup,
    [MusicCategoryTypes.WhiteNoise]: PageEvents.WhiteNoisePopup,
  }[category];

  if (pageEvent) {
    thunkAPI.dispatch(trackPage({ pageEvent, eventProps: undefined }));
  }
});

export const trackMusicPlayer = createAsyncThunk<void, string, { state: CombinedState<RootState> }>(
  `${NAME}/track-page--meditation-player`,
  async (uid, thunkAPI) => {
    const musicTrack = musicSelectors.getMusicTrackByTrackUid(thunkAPI.getState(), { uid });
    const track = audioTracksSelectors.getAudioTrackByUid(thunkAPI.getState(), { uid });

    await thunkAPI.dispatch(
      trackPage({
        pageEvent: PageEvents.Player,
        eventProps: {
          category: PageEvents.Player,
          type: PlayerTypes.Meditation,
          content_format: track.type,
          content_title: musicTrack.title,
          duration: track.duration / 60, // duration in minutes instead of seconds
          version_uuid: track.uid,
        },
      }),
    );
  },
);

export const trackProgramDetail = createAsyncThunk<void, string, { state: CombinedState<RootState> }>(
  `${NAME}/track-page--program-detail`,
  async (uid, thunkAPI) => {
    const course = courseSelectors.getCourseByUid(thunkAPI.getState(), { uid });
    await thunkAPI.dispatch(
      trackPage({
        pageEvent: PageEvents.ProgramDetails,
        eventProps: {
          category: 'programs',
          program_name: course.title,
          program_uuid: uid,
        },
      }),
    );
  },
);

export const trackProgramLessonPlayer = createAsyncThunk<void, string, { state: CombinedState<RootState> }>(
  `${NAME}/track-page--meditation-player`,
  async (uid, thunkAPI) => {
    const track = audioTracksSelectors.getAudioTrackByUid(thunkAPI.getState(), { uid });

    await thunkAPI.dispatch(
      trackPage({
        pageEvent: PageEvents.Player,
        eventProps: {
          category: PageEvents.Player,
          type: PlayerTypes.Meditation,
          content_format: track.type,
          content_title: track.title,
          duration: track.duration / 60, // duration in minutes instead of seconds
          version_uuid: track.uid,
        },
      }),
    );
  },
);

export const trackProgramLessonFinished = createAsyncThunk<void, string, { state: CombinedState<RootState> }>(
  `${NAME}/track-page--program-lesson-finished`,
  async (trackUid, thunkAPI) => {
    const track = audioTracksSelectors.getAudioTrackByUid(thunkAPI.getState(), { uid: trackUid });
    await thunkAPI.dispatch(
      trackPage({
        pageEvent: PageEvents.PlayerFinished,
        eventProps: {
          category: 'finished_flow',
          type: PlayerTypes.ProgramLesson,
          content_title: track.title,
          duration: track.duration / 60, // duration in minutes instead of seconds
          version_uuid: track.uid,
        },
      }),
    );
  },
);

export const trackMyProfilePage = createAsyncThunk<void, undefined, { state: CombinedState<RootState> }>(
  `${NAME}/track-page--my-profile`,
  async (_, thunkAPI) => {
    await thunkAPI.dispatch(
      trackPage({
        pageEvent: PageEvents.MyProfile,
        eventProps: { category: 'settings' },
      }),
    );
  },
);

export const trackMyProfileDetailPage = createAsyncThunk<void, undefined, { state: CombinedState<RootState> }>(
  `${NAME}/track-page--my-profile-details`,
  async (_, thunkAPI) => {
    await thunkAPI.dispatch(
      trackPage({
        pageEvent: PageEvents.MyProfileAccountDetails,
        eventProps: { category: 'settings' },
      }),
    );
  },
);

export const trackMyProfileLanguagePage = createAsyncThunk<void, undefined, { state: CombinedState<RootState> }>(
  `${NAME}/track-page--my-profile-language`,
  async (_, thunkAPI) => {
    await thunkAPI.dispatch(
      trackPage({
        pageEvent: PageEvents.MyProfileLanguageSettings,
        eventProps: { category: 'settings' },
      }),
    );
  },
);

export const trackMyProfileTimezonePage = createAsyncThunk<void, undefined, { state: CombinedState<RootState> }>(
  `${NAME}/track-page--my-profile-timezone`,
  async (_, thunkAPI) => {
    await thunkAPI.dispatch(
      trackPage({
        pageEvent: PageEvents.MyProfileChangeTimezone,
        eventProps: { category: 'settings' },
      }),
    );
  },
);

export const trackMyProfileGiftVoucherPage = createAsyncThunk<void, undefined, { state: CombinedState<RootState> }>(
  `${NAME}/track-page--my-profile-gift-voucher`,
  async (_, thunkAPI) => {
    await thunkAPI.dispatch(
      trackPage({
        pageEvent: PageEvents.MyProfileGiftVoucher,
        eventProps: { category: 'settings' },
      }),
    );
  },
);
