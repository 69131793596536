import { DeviceImageSet } from './image';

export type MusicCategory = {
  uid: string;
  slug: string;
  locale: string;
  url: string;
  title: string; // display_label
  description: string;
  position: number;
  type: MusicCategoryTypes;
  tracks: string[];
  subCategories?: MusicSubCategory[];
  dark: {
    image: DeviceImageSet;
    backgroundImage: DeviceImageSet;
    backgroundColor: string;
    purchaseColor: string;
  };
  light: {
    image: DeviceImageSet;
    backgroundImage: DeviceImageSet;
    backgroundColor: string;
    purchaseColor: string;
  };
};

export type MusicSubCategory = {
  uid: string;
  title: string; // display_label
  description: string;
  tracks: string[];
};

export enum MusicCategoryTypes {
  Default = 'default',
  Binaural = 'binaural',
  SoundHealing = 'sound_healing',
  WhiteNoise = 'white_noise',
}
