import { YogaFlowListOptions } from 'models/yoga-flow';
import { useCallback, useLayoutEffect } from 'react';
import { useRouter } from 'next/router';
import { MusicCategoryTypes } from 'models/music-category';
import { useBoundDispatch } from 'domains/redux/utils';
import { usePartOfTheDay } from 'domains/user';
import { MenuPageEvents, ViewAllSections } from 'services/tracking';
import * as PageThunks from './page-thunks';

export const useTrackSearch = (): (() => void) => {
  const track = useBoundDispatch(PageThunks.trackSearch);

  return useCallback(() => track(undefined), [track]);
};

export const useTrackNotFound = (): void => {
  const router = useRouter();
  const track = useBoundDispatch(PageThunks.trackNotFoundPage);

  useLayoutEffect(() => {
    track(router.asPath);
  }, [router.asPath]);
};

export const useTrackForYou = (): void => {
  const dayPart = usePartOfTheDay();
  const track = useBoundDispatch(PageThunks.trackForYouPage);

  useLayoutEffect(() => {
    track(dayPart);
  }, []);
};

export const useTrackMenuPage = (page: MenuPageEvents): void => {
  const track = useBoundDispatch(PageThunks.trackMenuPage);

  useLayoutEffect(() => {
    track(page);
  }, [page]);
};

export const useTrackViewAll = (section: ViewAllSections): void => {
  const track = useBoundDispatch(PageThunks.trackViewAll);

  useLayoutEffect(() => {
    track(section);
  }, [section]);
};

export const useTrackAllYogaVideos = (contentList: YogaFlowListOptions): void => {
  const track = useBoundDispatch(PageThunks.trackAllYogaVideos);

  useLayoutEffect(() => {
    track(contentList);
  }, [contentList]);
};

export const useTrackYogaDetail = (yogaItemUid: string): void => {
  const track = useBoundDispatch(PageThunks.trackYogaDetail);

  useLayoutEffect(() => {
    track(yogaItemUid);
  }, [yogaItemUid]);
};

export const useTrackYogaPlayer = (trackUid: string): (() => void) => {
  const track = useBoundDispatch(PageThunks.trackYogaPlayer);

  return useCallback(() => {
    track(trackUid);
  }, [track, trackUid]);
};

export const useTrackYogaFinished = (trackUid: string): void => {
  const track = useBoundDispatch(PageThunks.trackYogaFinished);

  useLayoutEffect(() => {
    track({ trackUid });
  }, [trackUid, track]);
};

export const useTrackAllFlowVideos = (contentList: YogaFlowListOptions): void => {
  const track = useBoundDispatch(PageThunks.trackAllFlowVideos);

  useLayoutEffect(() => {
    track(contentList);
  }, [contentList]);
};

export const useTrackFlowDetail = (flowItemUid: string): void => {
  const track = useBoundDispatch(PageThunks.trackFlowDetail);

  useLayoutEffect(() => {
    track(flowItemUid);
  }, [flowItemUid]);
};

export const useTrackFlowPlayer = (trackUid: string): (() => void) => {
  const track = useBoundDispatch(PageThunks.trackFlowPlayer);

  return useCallback(() => {
    track(trackUid);
  }, [track, trackUid]);
};

export const useTrackFlowFinished = (trackUid: string): void => {
  const track = useBoundDispatch(PageThunks.trackFlowFinished);

  useLayoutEffect(() => {
    track({ trackUid });
  }, [trackUid, track]);
};

export const useTrackMomentDetail = (momentUid: string): void => {
  const track = useBoundDispatch(PageThunks.trackMomentDetail);

  useLayoutEffect(() => {
    track(momentUid);
  }, [momentUid]);
};

export const useTrackMeditationDetail = (meditationUid: string): void => {
  const track = useBoundDispatch(PageThunks.trackMeditationDetail);

  useLayoutEffect(() => {
    track(meditationUid);
  }, [meditationUid]);
};

export const useTrackMeditationPlayer = (trackUid: string): (() => void) => {
  const track = useBoundDispatch(PageThunks.trackMeditationPlayer);

  return useCallback(() => {
    track(trackUid);
  }, [track, trackUid]);
};

export const useTrackMeditationFinished = (trackUid: string, includeRelated?: boolean): void => {
  const track = useBoundDispatch(PageThunks.trackMeditationFinished);

  useLayoutEffect(() => {
    track({ trackUid, includeRelated });
  }, [trackUid, track, includeRelated]);
};

export const useTrackMusicCategory = (categoryUid: string): void => {
  const track = useBoundDispatch(PageThunks.trackMusicCategory);

  useLayoutEffect(() => {
    track(categoryUid);
  }, [categoryUid]);
};

export const useTrackMusicCategoryInformation = (musicCategory: MusicCategoryTypes): void => {
  const track = useBoundDispatch(PageThunks.trackMusicCategoryInformation);

  useLayoutEffect(() => {
    track(musicCategory);
  }, [musicCategory]);
};

export const useTrackMusicPlayer = (trackUid: string, isOpen): void => {
  const track = useBoundDispatch(PageThunks.trackMusicPlayer);

  useLayoutEffect(() => {
    isOpen && trackUid && track(trackUid);
  }, [isOpen]);
};

export const useTrackProgramDetail = (courseUid: string): void => {
  const track = useBoundDispatch(PageThunks.trackProgramDetail);

  useLayoutEffect(() => {
    track(courseUid);
  }, [courseUid]);
};

export const useTrackProgramLessonPlayer = (trackUid: string): void => {
  const track = useBoundDispatch(PageThunks.trackProgramLessonPlayer);

  useLayoutEffect(() => {
    track(trackUid);
  }, [trackUid]);
};

export const useTrackProgramLessonFinished = (trackUid: string): void => {
  const track = useBoundDispatch(PageThunks.trackProgramLessonFinished);

  useLayoutEffect(() => {
    track(trackUid);
  }, [trackUid]);
};

export const useTrackMyProfile = (): void => {
  const track = useBoundDispatch(PageThunks.trackMyProfilePage);

  useLayoutEffect(() => {
    track(undefined);
  }, []);
};

export const useTrackMyProfileDetail = (): void => {
  const track = useBoundDispatch(PageThunks.trackMyProfileDetailPage);

  useLayoutEffect(() => {
    track(undefined);
  }, []);
};

export const useTrackMyProfileLanguageSettings = (): void => {
  const track = useBoundDispatch(PageThunks.trackMyProfileLanguagePage);

  useLayoutEffect(() => {
    track(undefined);
  }, []);
};

export const useTrackMyProfileTimezoneSettings = (): void => {
  const track = useBoundDispatch(PageThunks.trackMyProfileTimezonePage);

  useLayoutEffect(() => {
    track(undefined);
  }, []);
};

export const useTrackMyProfileGiftVoucher = (): void => {
  const track = useBoundDispatch(PageThunks.trackMyProfileGiftVoucherPage);

  useLayoutEffect(() => {
    track(undefined);
  }, []);
};
